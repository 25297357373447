import * as Desktop from '@app/desktop/desktopSchema';
import { Client } from '@app/core/client';
import { BaseError } from '@app/core/baseError';


const getResult = function(response: any) {
  if (response.hasOwnProperty('code')) {
    throw new BaseError({ code: response.code, detail: response.message });
  }
  return response;
}

export class ApiStub {
  private client: Client
  public keyStoreId!: string

  /*********************************************
   * Initialization
   *********************************************/

  constructor(public url: string) {
    this.client = new Client(this.url, 20404,{ contentType: 'application/json' })
  }

  setToken(token: string){
    this.client = new Client(this.url, 20404,{ contentType: 'application/json', authorization: `Bearer ${token}` })
  }

  async openSession(payload: Desktop.V2OpenSessionPayload): Promise<Desktop.V2OpenSessionResponse> {
    const response = await this.client.post('V2/sessions', payload)
    return getResult(response.body)
  }

  /*********************************************
   * Cert Management (generate, install, import)
   *********************************************/

  async generateCsr(payload: Desktop.V2GenerateCsrPayload): Promise<Desktop.V2GenerateCsrResponse> {
    const response = await this.client.post(`V2/keystores/${this.keyStoreId}/csr`, payload)
    return getResult(response.body)
  }

  async installPkcs7(payload: Desktop.V2InstallPkcs7Payload): Promise<Desktop.V2InstallPkcs7Response> {
    const response = await this.client.post(`V2/keystores/${this.keyStoreId}/certificate`, payload)
    return getResult(response.body)
  }

  async newProfile(payload: Desktop.V2NewProfilePayload): Promise<Desktop.V2NewProfileResponse> {
    const response = await this.client.post(`V2/keystores/${this.keyStoreId}/profiles`, payload)
    return getResult(response.body)
  }

  async initializeProfile(payload: Desktop.V2InitializeProfilePayload): Promise<Desktop.V2InitializeProfileResponse> {
    const response = await this.client.post(`V2/keystores/${this.keyStoreId}/initialize`, payload)
    return getResult(response.body)
  }

  async importCertificate(payload: Desktop.V2ImportCertificatePayload): Promise<Desktop.V2ImportCertificateResponse> {
    const response = await this.client.post(`V2/keystores/${this.keyStoreId}/import`, payload)
    return getResult(response.body)
  }

  /*********************************************
   * Listing & Info (certificate, keytores, profiles)
   *********************************************/

  async certificateList(payload: Desktop.V2CertificateListPayload = {}): Promise<Desktop.V2CertificateListResponse> {
    const response = await this.client.get('V2/certificates', payload)
    return getResult(response.body)
  }

  async keyStoreInfo(payload: Desktop.V2KeyStoreInfoPayload): Promise<Desktop.V2ListKeyStoresResponse> {
    const response = await this.client.get(`V2/keystores/${this.keyStoreId}`, payload)
    return getResult(response.body)
  }

  async listKeyStores(payload: Desktop.V2ListKeyStoresPayload = {}): Promise<Desktop.V2ListKeyStoresResponse> {
    const response = await this.client.get('V2/keystores', payload)
    return getResult(response.body)
  }

  async listProfiles(payload: Desktop.V2ListProfilesPayload = {}): Promise<Desktop.V2ListProfilesResponse> {
    const response = await this.client.get('V2/profiles', payload)
    return getResult(response.body)
  }

  /*********************************************
   * Signature
   *********************************************/

  async getSignature(payload: Desktop.V2SignaturePayload): Promise<Desktop.V2SignatureResponse> {
    const response = await this.client.post('V2/signatures', payload)
    return getResult(response.body)
  }

  /* este metodo esta disponible desde AD v3.3.x */
  async getSignatures(payload: Desktop.V2MultiSignaturePayload): Promise<Desktop.V2MultiSignatureResponse> {
    const response = await this.client.post('V2/multiSignature', payload)
    return getResult(response.body)
  }

  /*********************************************
   * TST
   *********************************************/

  async tstOpen(payload: any): Promise<any> {
    const response = await this.client.post('V2/tsts', payload);
    return response;
  }

  async tstStep(payload: any): Promise<any> {
    const response = await this.client.post('V2/tsts/step', payload);
    return response;
  }

  /*********************************************/

}
