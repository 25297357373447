export const DEFAULT_URL = 'https://alisonserver.certisur.com';

export const SIGN_OPTIONS = {
  hashAlgo: '2.16.840.1.101.3.4.2.1',
  signAlgo: '1.2.840.113549.1.1.1'
}

export const MOUNTH = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
