const ERROR = {
  code: 20401,
  detail: 'Undefined AlisonJS error'
}

export class BaseError extends Error {
  public code: number;
  public detail: string;

  constructor(error?: { code: number; detail?: string }) {
    error = error || ERROR
    super(`${error.code}: ${error.detail}`);
    this.code = error.code;
    this.detail = error.detail || '';
  }
}
