import { ApiStub } from '@app/tad/apiStub';

export default class TadInterface {
  private stub: ApiStub;

  constructor(url: string) {
    this.stub = new ApiStub(url);
  }

  async startSignature(args: any) {
    const response = await this.stub.tstStartSignature(args);
    return response;
  }

  async retrieve(args: any) {
    const response = await this.stub.retrieve(args);
    return response;
  }
}
