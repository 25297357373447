import * as Desktop from '@app/desktop/desktopSchema';
import { BaseError } from '@app/core/baseError'
import { Client } from '@app/core/client';
import { TICKET_POLL_INTERVAL } from '@app/desktop/defaults'

const getResult = function(response: any) {
  const opResult: Desktop.ResultStatus|undefined = response.opResult
  if (opResult === undefined) {
    throw new BaseError();
  }

  if (opResult.resultStatus == 2) {
    throw new BaseError(opResult.resultList[0]);
  } else {
    return response;
  }
}

export class ApiStub {
  private client: Client


  /*********************************************
   * Initialization
   *********************************************/

  constructor(url: string) {
    this.client = new Client(url, 20404,{ contentType: 'application/x-www-form-urlencoded' })
  }

  async openSession(payload: Desktop.V1OpenSessionPayload): Promise<Desktop.V1OpenSessionResponse> {
    const response = await this.client.post('V1/openSession', payload)
    return getResult(response.body)
  }

  async registerLicense(payload: Desktop.V1RegisterLicensePayload): Promise<Desktop.V1RegisterLicenseResponse> {
    const response = await this.client.post('V1/registerLicense', payload)
    return getResult(response.body)
  }

  /*--------------------------------------------
   * Private
   *-------------------------------------------*/

  private async startTicket(endpoint: string, payload: any): Promise<any> {
    const response = await this.client.post(endpoint, payload)
    const { ticket }: Desktop.V1FetchTicketResponse = getResult(response.body)
    return this.pollTicket(ticket.id)
  }

  private async pollTicket(id: string): Promise<any> {
    const { ticket, ticketResult } = await this.fetchTicket({ ticketId: id });
    if (ticket.status === 0) {
      return ticketResult;
    } else {
      await new Promise(resolve => setTimeout(resolve, TICKET_POLL_INTERVAL));
      return this.pollTicket(ticket.id);
    }
  }

  private async fetchTicket(payload: Desktop.V1FetchTicketPayload): Promise<Desktop.V1FetchTicketResponse> {
    const response = await this.client.post('V1/fetchTicket', payload)
    return getResult(response.body)
  }


  /*********************************************
   * Cert Management (generate, install, import)
   *********************************************/

   async generateCsr(payload: Desktop.V1GenerateCsrPayload): Promise<Desktop.V1GenerateCsrResponse> {
    const response = await this.startTicket('V1/genCSR', payload)
    return getResult(response)
  }
  async installPkcs7(payload: Desktop.V1InstallPkcs7Payload): Promise<Desktop.V1InstallPkcs7Response> {
    const response = await this.startTicket('V1/installP7B', payload)
    return getResult(response)
  }
  async initializeProfile(payload: Desktop.V1InitializeProfilePayload): Promise<Desktop.V1InitializeProfileResponse> {
    const response = await this.startTicket('V1/initializeProfile', payload)
    return getResult(response)
  }

  /*
  async importCertificate(payload: Desktop.V1ImportCertificatePayload): Promise<Desktop.V1ImportCertificateResponse> {
    const response = await this.startTicket('V1/migrateCertificate', payload)
    return getResult(response)
  }
  */

  /*********************************************
   * Listing & Info (certificate, keytores, profiles)
   *********************************************/

   async certificateList(payload: Desktop.V1CertificateListPayload): Promise<Desktop.V1CertificateListResponse> {
    const response = await this.startTicket('V1/getCertificateList', payload)
    return getResult(response)
  }
  async keyStoreInfo(payload: Desktop.V1KeyStoreInfoPayload): Promise<Desktop.V1KeyStoreInfoResponse> {
    const response = await this.startTicket('V1/getKeyStoreInfo', payload)
    return getResult(response)
  }

  /*********************************************
   * Signature
   *********************************************/


  async sign(payload: Desktop.V1SignPayload): Promise<Desktop.V1SignResponse> {
    const response = await this.startTicket('V1/sign', payload)
    return getResult(response)
  }


  /*********************************************
   * TST
   *********************************************/

  /*********************************************/

}
