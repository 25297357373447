import { Client } from '@app/core/client';
import { BaseError } from '@app/core/baseError';


const getResult = function(response: any) {
  if (response.code >= 400) {
    throw new BaseError(response.body);
  }
  return response.body;
};

export class ApiStub {
  private client: Client
  public keyStoreId!: string

  constructor(public url: string) {
    this.client = new Client(this.url, 20422, { contentType: 'application/json' });
  }

  async tstStartSignature(payload: any): Promise<any> {
    const response = await this.client.post('start_signature',payload);
    return getResult(response);
  }
  async retrieve(payload: any): Promise<any> {
    const response = await this.client.get(`retrieve/${payload.uuid}`,null);
    return getResult(response);
  }
}
