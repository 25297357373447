import * as Desktop from '@app/desktop/desktopSchema';
import { Client } from '@app/core/client';

const isImgLoaded = function(img: any) {
  if (!img.complete) {
    return false;
  }
  if (typeof img.naturalWidth !== 'undefined' && img.naturalWidth == 0) {
    return false;
  }
  return true;
}

export class ApiStub {
  private client: Client

  /*********************************************
   * Initialization
   *********************************************/

  constructor(url: string) {
    this.client = new Client(url, 20404,{ contentType: 'application/x-www-form-urlencoded' })
  }

  async healthCheck(payload: Desktop.V0HealthCheckPayload = {protocol: 'V1'}): Promise<Desktop.V0HealthCheckResponse> {
    const response = await this.client.post('V0/getComponentInfo', payload)
    return response.body
  }

  /*********************************************
   * Detection (isEnabled, isRunning...)
   *********************************************/


  async isEnabled(): Promise<boolean> {
    const img = document.createElement("img");
    img.src='http://127.0.0.1:8003/V0/isTrustedCAInstalled?'+ Math.floor((Math.random() * 100) + 100000);

    return new Promise(resolve => setTimeout(() => {
      resolve(isImgLoaded(img))
    }, 2500));
  }

  async isRunning(): Promise<boolean> {
    const img = document.createElement("img");
    img.src="http://127.0.0.1:8003/V0/isAlisonDesktopRunning?"+ Math.floor((Math.random() * 100) + 100000);

    return new Promise(resolve => setTimeout(() => {
      resolve(isImgLoaded(img))
    }, 2500));
  }


  async enable(): Promise<void> {
    const img = document.createElement("img");
    img.src="http://127.0.0.1:8003/V0/installTrustedCA?"+ Math.floor((Math.random() * 100) + 100000);

    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (isImgLoaded(img)){
          resolve();
          clearInterval(interval);
        }
      }, 2500);
    });
  }

  /*********************************************/

}
