
import TadInterface from '@app/tad/interfaceStub';

const DEFAULT_URL = 'http://docker.verisur:9292';

export default class AlisonTadService {
  private stub!: TadInterface;
  private session = ''
  constructor(url: string = DEFAULT_URL) {
    this.stub = new TadInterface(url);
  }

  async setSession(session:string) {
    this.session = session;
  }

  async startSignature(args: any) {
    let params = args;
    if(this.session !== '') params = {...params, session: this.session};
    return this.stub.startSignature(params);
  }

  async retrieve(args: any) {
    return this.stub.retrieve(args);
  }

}
