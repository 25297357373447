import { ApiStub } from '@app/desktop/v1/apiStub';
import {
  IGenProvider, ISignProvider, IKeyStoreProvider,
  IKeyStoreSelector, ICertificateSelector, IKeyGenOptions, ISecurityPolicy
} from '@app/core/interfaces';
import WebCertificate from '@app/core/webCertificate';
import KeyStore from '@app/core/keyStore';
import Base64 from 'crypto-js/enc-base64';
import { BaseError } from '@app/core/baseError';
import Utf8 from 'crypto-js/enc-utf8';
import { IDesktopFilter } from '@app/core/model/Filter/IDesktopFilter';

import sha1 from 'sha1';

import { AUTH_TOKEN, GEN_OPTIONS, KEYSTORES, PROFILES } from '@app/desktop/defaults';

export default class DesktopInterface implements IGenProvider, ISignProvider,
                                                 IKeyStoreProvider {
  private stub: ApiStub;

  /*********************************************
   * Initialization
   *********************************************/

  constructor(url: string) {
    this.stub = new ApiStub(url);
  }

  async initialize({ accessToken }: { accessToken: string }) {
    const license = { version: 2, idx: '', value: '', magic:'' };
    try {
      const parsed = JSON.parse(Utf8.stringify(Base64.parse(accessToken)));
      license.idx = parsed.v1 && parsed.v1.idx;
      license.value = parsed.v1 && parsed.v1.value;
      license.magic = parsed.v1 && parsed.v1.magic;

    } catch (e) {
      throw new BaseError({ code: 20405, detail: 'malformed license' });
    }
    await this.stub.registerLicense({ license });

    const { session } = await this.stub.openSession({ authToken: AUTH_TOKEN });
    return { session: session.id };
  }

  /*********************************************
   * Cert Management (generate, install, import)
   *********************************************/
  async generateCsr({
    keyStore,
    options,
    securityPolicy
  }: {
    keyStore: IKeyStoreSelector;
    options?: IKeyGenOptions;
    securityPolicy?: ISecurityPolicy;
  }) {
    const genOptions = { ...GEN_OPTIONS, ...options };
    const { certificateRequest } = await this.stub.generateCsr({
      genCSRConfig: {
        privateKeyAlgorithm: genOptions.algorithm,
        privateKeySize: genOptions.size,
        csrAlgorithm: genOptions.signatureAlgorithm,
        keyStoreSelector: {
          keyStoreId: keyStore.id,
          profileName: keyStore.profile
        },
        secPolicy: securityPolicy || {}
      }
    });
    return { csr: certificateRequest.csr, profileName: certificateRequest.profileName};
  }

  async installPkcs7({
    keyStore,
    pkcs7,
    securityPolicy
  }: {
    keyStore: IKeyStoreSelector;
    pkcs7: string;
    securityPolicy?: ISecurityPolicy;
  }) {
    const { webCertificate } = await this.stub.installPkcs7({
      installP7BConfig: {
        keyStoreSelector: {
          keyStoreId: keyStore.id,
          profileName: keyStore.profile
        },
        p7b: pkcs7,
        secPolicy: securityPolicy || {}
      }
    });
    return { certificate: new WebCertificate(webCertificate) };
  }

  async newProfile({
    keyStore,
    securityPolicy
  }: {
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
  }) {
    const genOptions = GEN_OPTIONS;
    const { certificateRequest } = await this.stub.generateCsr({
      genCSRConfig: {
        privateKeyAlgorithm: genOptions.algorithm,
        privateKeySize: genOptions.size,
        csrAlgorithm: genOptions.signatureAlgorithm,
        keyStoreSelector: {
          keyStoreId: keyStore.id,
          profileName: keyStore.profile
        },
        secPolicy: securityPolicy || {}
      }
    });
    return {
      profile: certificateRequest.profileName,
      recovery: certificateRequest.recoveryKey
    };
  }

  async initializeProfile({
    keyStore,
    securityPolicy,
    password,
    regenRecoveryKey
  }: {
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
    password?: string;
    regenRecoveryKey?: boolean;
  }) {
    const { profileName, recoveryKey } = await this.stub.initializeProfile({
      keyStore: keyStore,
      securityPolicy: securityPolicy,
      password: password,
      regenRecoveryKey: regenRecoveryKey
    });
    return { profile: profileName, recovery: recoveryKey };
  }


  /*********************************************
   * Listing & Info (certificate, KeyStores, profiles)
   *********************************************/

  async certificateList(filters?: IDesktopFilter[]) {
    const { webCertificateList } = await this.stub.certificateList({
      certificateListConfig: {
        keyStoreFilter: KEYSTORES,
        forceReload: false
      }
    });    
    const certificates = webCertificateList.map(cert => new WebCertificate(cert));
    if (certificates.length > 0) {
      if (filters === undefined)
        return { certificates };
      else
        return { certificates: certificates.filter(cert => cert.matchs(filters)) };
    } else {
      return { certificates: [] };
    }
  }

  async listProfiles() {
    return { profiles: PROFILES };
  }

  async listKeyStores() {
    return { keyStores: KEYSTORES };
  }

  async keyStoreInfo(keyStore: IKeyStoreSelector) {
    const { keyStoreInfo } = await this.stub.keyStoreInfo({ keyStoreId: keyStore.id });
    return { keyStore: new KeyStore(keyStoreInfo) };
  }

  /*********************************************
   * Signature
   *********************************************/


  async getSignature({
    dataToSignB64,
    format,
    algorithm,
    extraParams,
    signingCertificate
  }: {
    dataToSignB64: string;
    format?: string;
    algorithm: string;
    extraParams?: string;
    signingCertificate: ICertificateSelector
  }) {
    const { signature } = await this.stub.sign({
      signatureRequest: {
        id: Math.random().toString(36).substring(2, 15),
        crc: sha1(dataToSignB64),
        data2signB64: dataToSignB64,
        signatureAlgorithm: algorithm,
        signatureType: format || 'NONE',
        extraParams: extraParams || ''
      },
      certSelector: {
        thumbPrint: signingCertificate.thumbPrint,
        keyStoreId: signingCertificate.keyStoreId,
        profileName: signingCertificate.profileName || ''
      }
    });
    return {
      signature: signature.signature,
      certificate: new WebCertificate(signature.certificate),
      profileId: ''
    };
  }


  /*********************************************
   * TST
   *********************************************/

  async stamp(args: any) {
    return args;
  }
  async tstOpen() {
    return new BaseError({ code: 9999, detail: 'not found' });
  }
  async tstStep() {
    return new BaseError({ code: 9999, detail: 'not found' });
  }

  /*********************************************/

}

