import { ApiStub } from '@app/desktop/v2/apiStub';
import {
  IGenProvider, ISignProvider, IKeyStoreProvider, ICertificateProvider,
  IKeyStoreSelector, ICertificateSelector, IKeyGenOptions, ISecurityPolicy
} from '@app/core/interfaces';

import WebCertificate from '@app/core/webCertificate';
// import KeyStore from '@app/core/keyStore';
import { BaseError } from '@app/core/baseError';
import { GEN_OPTIONS /*, SIGN_OPTIONS*/ } from '@app/desktop/defaults';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import { IDesktopFilter } from '@app/core/model/Filter/IDesktopFilter';

export default class DesktopInterface implements IGenProvider, ISignProvider,
                                                 ICertificateProvider, IKeyStoreProvider {

  private stub: ApiStub;

  /*********************************************
   * Initialization
   *********************************************/

  constructor(url: string) {
    this.stub = new ApiStub(url);
  }

  async initialize({ accessToken }: { accessToken: string }) {
    let license = { version: 3, idx: '', value: '' };
    try {
      const parsed = JSON.parse(Utf8.stringify(Base64.parse(accessToken)));
      license.idx = parsed.v2 && parsed.v2.idx;
      license.value = parsed.v2 && parsed.v2.value;
    } catch (e) {
      throw new BaseError({ code: 20405, detail: 'malformed license' })
    }
    const { session } = await this.stub.openSession({ license });
    this.stub.setToken(session);
    return { session };
  }

  /*********************************************
   * Cert Management (generate, install, import)
   *********************************************/

  async generateCsr({
    keyStore,
    options,
    securityPolicy
  }: {
    keyStore: IKeyStoreSelector;
    options?: IKeyGenOptions;
    securityPolicy?: ISecurityPolicy;
  }) {
    const genOptions = { ...GEN_OPTIONS, ...options };
    this.stub.keyStoreId = keyStore.id;
    const response = await this.stub.generateCsr({
      keyAlgorithm: genOptions.algorithm,
      keySize: genOptions.size,
      signatureAlgorithm: genOptions.signatureAlgorithm,
      profileName: keyStore.profile,
      securityPolicy: securityPolicy
    });
    return response;
  }

  async installPkcs7({
    keyStore,
    pkcs7,
    securityPolicy
  }: {
    keyStore: IKeyStoreSelector;
    pkcs7: string;
    securityPolicy?: ISecurityPolicy;
  }) {
    this.stub.keyStoreId = keyStore.id;
    const { certificate } = await this.stub.installPkcs7({
      pkcs7: pkcs7,
      profileName: keyStore.profile,
      securityPolicy: securityPolicy
    });
    return { certificate: new WebCertificate(certificate) };
  }

  async newProfile({
    keyStore,
    securityPolicy
  }: {
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
  }) {
    this.stub.keyStoreId = keyStore.id;
    const { profile, recoveryKey } = await this.stub.newProfile({
      securityPolicy: securityPolicy || {}
    });
    return { profile, recoveryKey };
  }

  async initializeProfile({
    keyStore,
    securityPolicy,
    password,
    regenRecoveryKey,
  }: {
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
    password?: string;
    regenRecoveryKey?: boolean;
  }) {
    this.stub.keyStoreId = keyStore.id;
    const { profileName, recoveryKey } = await this.stub.initializeProfile({
      profileName: keyStore.profile,
      securityPolicy: securityPolicy,
      password: password,
      regenRecoveryKey: regenRecoveryKey
    });
    return { recovery: recoveryKey,
             profile: profileName };
  }

  async importCertificate({
    certificateToImport,
    keyStore,
    securityPolicy
  }: {
    certificateToImport: ICertificateSelector;
    keyStore: IKeyStoreSelector;
    securityPolicy?: ISecurityPolicy;
  }) {
    this.stub.keyStoreId = keyStore.id;
    const { certificate, profileId, profileName, keyStoreId, recoveryKey } = await this.stub.importCertificate({
      certificateSelector: certificateToImport,
      profileName: keyStore.profile,
      securityPolicy: securityPolicy
    });
    return { certificate: new WebCertificate(certificate),
             recovery: recoveryKey,
             profileId: profileId,
             profileName: profileName,
             keyStoreId: keyStoreId };
  }


  /*********************************************
   * Listing & Info (certificate, keytores, profiles)
   *********************************************/

  async certificateList(filters?: IDesktopFilter[]) {
    const { certificates } = await this.stub.certificateList();
    const parsedCertificates = certificates.map(cert => new WebCertificate(cert))
    if (parsedCertificates.length > 0) {
      if (filters === undefined)
        return { certificates: parsedCertificates };
      else
        return { certificates: parsedCertificates.filter(cert => cert.matchs(filters)) };
    } else {
      return { certificates: [] };
    }
  }

  async listKeyStores() {
    const { keyStores } = await this.stub.listKeyStores();
    return { keyStores };
  }

  async listProfiles() {
    const { profiles } = await this.stub.listProfiles();
    return { profiles };
  }

  async keyStoreInfo(keyStore: IKeyStoreSelector) {
    this.stub.keyStoreId = keyStore.id;
    const { keyStores }  = await this.stub.keyStoreInfo({
      keyStoreId: keyStore.id
    });
    return { keyStores };
  }


  /*********************************************
   * Signature
   *********************************************/

  async getSignature({
    dataToSignB64,
    format,
    algorithm,
    extraParams,
    signingCertificate
  }: {
    dataToSignB64: string;
    format?: string;
    algorithm: string;
    extraParams?: string;
    signingCertificate: ICertificateSelector
  }) {

    const { signResultB64, certificate, profileId } = await this.stub.getSignature({
      base64ToSign: dataToSignB64,
      format: format || "NONE",
      algorithm: algorithm,
      extraParams: extraParams || "",
      certificateSelector: {
        thumbPrint: signingCertificate.thumbPrint,
        keyStoreId: signingCertificate.keyStoreId,
        profileName: signingCertificate.profileName || ""
      }
    });
    return { signature: signResultB64,
             certificate: new WebCertificate(certificate),
             profileId: profileId };
  }

  async getSignatures({
    dataToSignB64,
    format,
    algorithm,
    extraParams,
    signingCertificate
  }: {
    dataToSignB64: [string];
    format?: string;
    algorithm: string;
    extraParams?: string;
    signingCertificate: ICertificateSelector
  }) {
    let response: any = {signatures:[]};
    for (let index = 0; index < dataToSignB64.length; index++) {
      const { signResultB64, certificate, profileId } = await this.stub.getSignature({
        base64ToSign: dataToSignB64[index],
        format: format || "NONE",
        algorithm: algorithm,
        extraParams: extraParams || "",
        certificateSelector: {
          thumbPrint: signingCertificate.thumbPrint,
          keyStoreId: signingCertificate.keyStoreId,
          profileName: signingCertificate.profileName || ""
        }
      });
      response.profileId = profileId;
      response.certificate = new WebCertificate(certificate);
      response.signatures.push(signResultB64);
    }
    return response;
  }

  /*********************************************
   * TST
   *********************************************/

  async tstOpen(args: any) {
    const response = await this.stub.tstOpen(args);
    return response;
  }
  async tstStep(args: any) {
    const response = await this.stub.tstStep(args);
    return response;
  }

  /*********************************************/

}
