import { ApiStub } from '@app/server/v1/apiStub';
import {
  ISignProvider,
} from '@app/core/interfaces';
import WebServerCertificate from '@app/core/webServerCertificate';
import { BaseError } from '@app/core/baseError';

export default class ServerInterface implements ISignProvider {
  private stub: ApiStub;

  constructor(url: string) {
    this.stub = new ApiStub(url);
  }

  async initialize(args: any) {
    await this.stub.setToken(args);
    const result = await this.stub.checkToken(args.token);
    if ("seatExists" in result && result.seatExists === false) {
      throw new BaseError({ code: 20406, detail: 'Seat not exists' });
    }
    return {session: ''};
  }

  async certificateList() {
    const certificates = await this.stub.certificateList();
    return { certificates };
  }

  async certificateInfo(certId: any) {
    const certificates = await this.stub.certificateInfo(certId);
    return new WebServerCertificate(certificates);
  }

  async authorizeCredentials(args: any) {
    const response = this.stub.authorizeCredentials(args);
    return response;
  }

  async getEmailCode(args: any) {
    const response = this.stub.getEmailCode(args);
    return response;
  }

  async getSmsCode(args: any) {
    const response = this.stub.getSmsCode(args);
    return response;
  }
/*
  async sign(args:any) {
    const response = await this.stub.signature(args);
    return response;
  }
*/

  async getSignature(args:any) {
    const response = await this.stub.signature(args);
    return response;
  }

}
