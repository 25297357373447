import { SignFormat } from '@app/core/model/SignFormat';
import { DEFAULT_URL, SIGN_OPTIONS } from '@app/server/defaults';

import ServerInterface from '@app/server/v1/interfaceStub';

export default class AlisonServerService {
  private stub!: ServerInterface;
  public version: string = ''
  constructor(url: string = DEFAULT_URL) {
    this.stub = new ServerInterface(url);
  }

  async initialize(args:  any ) {
    return this.stub.initialize(args);
  }

  async certificateList() {
    return this.stub.certificateList();
  }

  async certificateInfo(args:  any) {
    return this.stub.certificateInfo(args);
  }

  async authorizeCredentials(args: {
    credentialId: string,
    numSignatures: number,
    hash: Array<string>,
    PIN?: string,
    OTP?: string,
    SMS?: string,
    EMAIL?: string,
  }){
    return this.stub.authorizeCredentials(args);
  }

  async getEmailCode(args: {
    language: string
  }){
    return this.stub.getEmailCode(args);
  }

  async getSmsCode(args: {
    language: string
  }){
    return this.stub.getSmsCode(args);
  }

  async signHash(args: {
    credentialId: string,
    SAD: string,
    hash: Array<string>,
    hashAlgo: string,
    signAlgo: string,
    signFormat: SignFormat,
  }) {
    return this.stub.getSignature({ ...args, ...SIGN_OPTIONS});
  }
};
