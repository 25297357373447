import querystring from 'querystring';
import { BaseError } from '@app/core/baseError';

export class Client {
  private url: string;
  private defaultError: number
  private authorization?: string
  private tenant?: string
  private contentType = 'application/json;'

  constructor(url: string, defaultError: number, headers: { authorization?: string, contentType?: string, tenant?: string } ) {
    this.url = url;
    this.defaultError = defaultError;
    this.authorization = headers.authorization;
    this.tenant = headers.tenant;
    this.contentType = headers.contentType || this.contentType;
  }

  private preparePayload(payload: any): any {
    switch (this.contentType.split(';')[0]) {
    case 'application/json': {
      return JSON.stringify(payload);
    }
    case 'application/x-www-form-urlencoded': {
      Object.entries(payload).forEach(
        ([key, value]) => (payload[key] = typeof value === 'string' ? value : JSON.stringify(value))
      );
      return querystring.stringify(payload);
    }
    case 'multipart/form-data': {
      let formData = new FormData();
      Object.entries(payload).forEach(
        ([key, value]) => {
          if (typeof value === 'string' || value instanceof Blob) {
            formData.append(key, value);
          } else {
            console.error(`Value for key ${key} is not a string.`);
          }
        }
      );
      return formData;
    }
    default: {
      return '';
    }
    }
  }

  private send(method: string, endpoint: string, payload: any): Promise<Response> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, `${this.url}/${endpoint}`);
      if (this.contentType != "multipart/form-data") {
        xhr.setRequestHeader('Content-type', this.contentType);
      }
      if (this.authorization) {
        xhr.setRequestHeader('Authorization', this.authorization);
      }
      if(this.tenant) {
        xhr.setRequestHeader('X-Tenant', this.tenant);
      }
      xhr.onload = () => {
        resolve(new Response(xhr.status, xhr.response));
      };

      xhr.onerror = xhr.ontimeout = () => {
        reject(new BaseError({ code: this.defaultError, detail: 'Provider response Timeout.' }));
      };

      xhr.send(this.preparePayload(payload));
    });
  }

  public changeContentType(newContentType: string) {
    this.contentType = newContentType;
  }

  public async post(endpoint: string, payload: any): Promise<Response> {
    return this.send('POST', endpoint, payload);
  }

  public async get(endpoint: string, payload: any): Promise<Response> {
    return this.send('GET', endpoint, payload);
  }
}

export class Response {
  constructor(
    public code: number,
    public raw: string,
    public cookies: { [k: string]: any } = {},
    public headers: { [k: string]: any } = {}
  ) { }

  get body() {
    try {
      return JSON.parse(this.raw);
    } catch(e) {
      return {};
    }
  }
}
