import { Certificate, ResultStatus } from '@app/desktop/desktopSchema';
import { IWebCertificate } from './interfaces';
import { IDesktopFilter } from './model/Filter/IDesktopFilter';


function formatDate(date: Date) {
  return [
    date.getDate(),
    date.toLocaleString('en-US', { month: 'short' }),
    date.getFullYear()
  ].join('-');
}

export default class WebCertificate implements IWebCertificate {
  constructor(protected cert: Certificate) {}

  get serialNumber() {
    return this.cert.serialNumberH;
  }
  get thumbPrint() {
    return this.cert.thumbPrint;
  }
  get subject() {
    return {
      DN: this.cert.subject.DN,
      CN: this.cert.subject.parsedDN.CN,
      EMAILADDRESS: this.cert.subject.parsedDN.EMAILADDRESS,
    }
  }
  get issuer() {
    return {
      DN: this.cert.issuer.DN,
      CN: this.cert.issuer.parsedDN.CN,
      O: this.cert.issuer.parsedDN.O,
    }
  }

  get validity() {
    return {
      isExpired: this.cert.daysToExpire < 0,
      from: formatDate(new Date(1000 * this.cert.notBeforeTS)),
      left: this.cert.daysToExpire,
      until: formatDate(new Date(1000 * this.cert.notAfterTS)),
      total: Math.round((this.cert.notAfterTS - this.cert.notBeforeTS) / (60 * 60 * 24))
    };
  }

  get profile() {
    return {
      id: this.cert.keyStoreId,
      keyStore: this.cert.keyStoreId,
      keyStoreType: this.cert.keyStoreType,
      name: this.cert.profile && this.cert.profile.name,
      friendlyName: this.cert.profile && this.cert.profile.friendlyName,
      keyStoreFriendlyName: this.cert.profile && this.cert.profile.keyStoreFriendlyName,
      status: this.cert.profile && this.parseResultStatus(this.cert.profile.status)
    };
  }

  private parseResultStatus({ resultList, resultStatus }: ResultStatus) {
    return {
      code: (resultList[0] && resultList[0].code) || 0,
      critical: resultStatus == 2
    }
  }

  matchs(filters: IDesktopFilter[]) {    
    let unfiltered = (filters.length === 0);
    filters.forEach((currentFilters : IDesktopFilter) => {
      let filterPass = true;
      Object.entries(currentFilters).forEach(([filterKey, filterValue]) => {
        let windowGap = [];
        const certificate = this.cert;
        switch (filterKey) {
          case 'keystore':
            if (filterValue) {
              filterPass = filterPass && filterValue.includes(certificate.keyStoreId);
            }
            break;
          case 'windowValidity':
            if (filterValue) {
              windowGap = filterValue.split(',');
            }
            if ((windowGap[0] !== '*' && windowGap[0] > certificate.daysToExpire)
              || (windowGap[1] !== '*' && certificate.daysToExpire > windowGap[1])) { filterPass = false; }
            break;
          case 'expirationWindow':
            if (filterValue) {
              windowGap = filterValue.split(',');
            }
            if ((windowGap[0] !== '*' && windowGap[0] > certificate.daysToExpire)
              || (windowGap[1] !== '*' && certificate.daysToExpire > windowGap[1])) { filterPass = false; }
            break;
          case 'issuer':
            if (filterValue) {
              filterPass = filterPass && certificate.issuer.DN.includes(filterValue);
            }            
            break;
          case 'subject':
            if (filterValue) {
              filterPass = filterPass && certificate.subject.DN.includes(filterValue);
            }
            break;
          default:
            filterPass = true;
        }
      });
      unfiltered = filterPass || unfiltered;
    });    
    return unfiltered;
  }
}
